import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useMemo, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { Widget } from '../Shared/Widget';
import { useFormattedData } from './useFormattedData';
import { SortSwitch } from '../Shared/SortSwitch';
import { TotalActionsPerOrganizationItem } from '@/app/types/Dashboard';
import './style.css';

interface CustomSeriesOptions extends Highcharts.Options {
	itemTotal?: number;
}

interface Props {
	isLoading: boolean;
	data: TotalActionsPerOrganizationItem[];
}

export const TotalActionsPerOrganization = ({ isLoading, data }: Props) => {
	const [ascending, setAscending] = useState<boolean>(true);
	const options = useFormattedData(data);

	const sortedOptions = useMemo(() => {
		if (!isLoading && options && options?.['series'] && Array.isArray(options?.['series'])) {
			const deepClonedOptions = cloneDeep(options);
			const sortData = {
				...deepClonedOptions,
				series: (deepClonedOptions.series as CustomSeriesOptions[]).sort((a, b) => {
					return ascending
						? (a.itemTotal ?? 0) - (b.itemTotal ?? 0)
						: (b.itemTotal ?? 0) - (a.itemTotal ?? 0);
				}),
			};
			return sortData;
		}
	}, [ascending, isLoading, options]);

	return (
		<Widget
			isLoading={isLoading}
			title="Number of Actions per Organization"
			toolbar={<SortSwitch value={ascending} onChange={(e) => setAscending(e)} />}
		>
			<HighchartsReact options={sortedOptions} highcharts={Highcharts} />
		</Widget>
	);
};
