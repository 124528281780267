import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { Widget } from '../Shared/Widget';
import { SortSwitch } from '../Shared/SortSwitch';

import { Filter } from './Filter';
import { useFormattedData } from './useResponseRatioGraph';
import { ResponseRatioFilter, ResponseRatioItem } from '@/app/types/Dashboard';

interface Props {
	isLoading: boolean;
	data: ResponseRatioItem[];
}

export const ResponseRatio = ({ data = [], isLoading }: Props) => {
	const [ascending, setAscending] = useState<boolean>(true);
	const [filter, setFilter] = useState<ResponseRatioFilter>('all');

	const sortedData = [...data].sort((a, b) => {
		if (filter === 'accepted') {
			return ascending ? a.accepted - b.accepted : b.accepted - a.accepted;
		}

		if (filter === 'declined') {
			return ascending ? a.rejected - b.rejected : b.rejected - a.rejected;
		}

		if (filter === 'unanswered') {
			return ascending ? a.unanswered - b.unanswered : b.unanswered - a.unanswered;
		}

		return ascending ? a.total - b.total : b.total - a.total;
	});

	const options = useFormattedData(sortedData, filter);

	return (
		<Widget
			minChartHeight={360}
			isLoading={isLoading}
			title="Actions Response Ratio"
			toolbar={
				<>
					<Filter value={filter} onChange={setFilter} />
					<SortSwitch value={ascending} onChange={(e) => setAscending(e)} />
				</>
			}
		>
			<HighchartsReact options={options} highcharts={Highcharts} />
		</Widget>
	);
};
