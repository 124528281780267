import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useState } from 'react';
import { Widget } from '../Shared/Widget';
import { SortSwitch } from '../Shared/SortSwitch';
import { useFormattedData } from './useFormattedData';
import { AcceptanceTimeItem } from '@/app/types/Dashboard';

interface Props {
	isLoading: boolean;
	data: AcceptanceTimeItem[];
}

export const AcceptanceTimeWidget = ({ data = [], isLoading }: Props) => {
	const [ascending, setAscending] = useState<boolean>(true);

	const sortedData = [...data].sort((a, b) => {
		return ascending ? a.averageTime - b.averageTime : b.averageTime - a.averageTime;
	});

	const options = useFormattedData(sortedData);

	return (
		<Widget
			isLoading={isLoading}
			title="Actions Acceptance Time"
			toolbar={<SortSwitch value={ascending} onChange={(e) => setAscending(e)} />}
		>
			<HighchartsReact options={options} highcharts={Highcharts} />
		</Widget>
	);
};
