import { useMemo } from 'react';
import { ColorString } from 'highcharts';
import { AcceptanceTimeItem } from '@/app/types/Dashboard';
import { toHSL } from '@/utils/color';

export const config: Highcharts.Options = {
	chart: {
		type: 'bar',
		backgroundColor: 'transparent',
		marginRight: 20,
		marginBottom: 36,
		height: 300,
	},
	accessibility: {
		enabled: false,
	},
	plotOptions: {
		bar: {
			grouping: false,

			dataLabels: {
				enabled: true,
				verticalAlign: 'middle',
				color: 'transparent',
				style: {
					fontSize: '16px',
					fontFamily: 'Graphik-Semibold',
					fontWeight: '400',
					textOutline: 'none',
				},
			},
		},
	},
	credits: {
		enabled: false,
	},
	title: {
		text: '',
	},
	tooltip: {
		enabled: false,
	},
	legend: {
		enabled: false,
		itemStyle: {
			fontSize: '16px',
			fontFamily: 'Graphik',
			fontWeight: '400',
		},
		align: 'left',
		verticalAlign: 'middle',
		layout: 'vertical',
		symbolHeight: 8,
		symbolWidth: 8,
		x: -16,
	},
	xAxis: {
		labels: {
			enabled: false,
		},
	},
	yAxis: {
		labels: {
			enabled: true,
			style: {
				fontSize: '14px',
				color: '#B6B6B6',
				fontFamily: 'Graphik',
				fontWeight: '600',
				alignItems: 'center',
				textAlign: 'center',
			},
			align: 'center',
			useHTML: true,
			reserveSpace: true,
			formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
				if (this.isFirst || this.isLast) {
					return `${this.value}`;
				}
				return '';
			},
		},
		title: {
			text: 'Seconds',
			style: {
				fontSize: '14px',
				color: '#B6B6B6',
				fontFamily: 'Graphik',
				textOverflow: 'none',
				fontWeight: '600',
				width: 150,
			},

			y: -20,
			x: 0,
		},

		alignTicks: true,
		allowDecimals: false,
		startOnTick: true,
		endOnTick: true,
		gridLineDashStyle: 'Dash',
		gridLineColor: '#9194A1',
		tickAmount: 7,
		min: 0,

		showFirstLabel: true,
		showLastLabel: true,
	},
	series: [],
};

export const useFormattedData = (data: AcceptanceTimeItem[] = []) => {
	return useMemo(() => {
		try {
			if (!data || data.length === 0) {
				return null;
			}

			return {
				...config,
				xAxis: {
					type: 'category',

					labels: {
						y: 5,
						x: -14,
						align: 'left',
						formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
							return `<div style="display: flex; align-items: center; justify-content: flex-start; padding: 0 8px; border-radius: 6px;">
						 		<div style="color: ${this.axis.series[this.pos].color as ColorString}; font-size:16px; font-family: Graphik-Semibold; margin-right: 8px">&#9679;</div>
							 	<span style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-family: Graphik; font-size: 16px;">${this.value}</span>
							</div>`;
						},
						useHTML: true,
						reserveSpace: true,
					},
					lineWidth: 0,
				},
				yAxis: {
					...config.yAxis,
				},
				series: data.map((item) => {
					return {
						colorKey: 'x',
						name: item.organizationName,
						data: [{ y: item.averageTime, name: item.organizationName }],
						type: 'bar',
						borderRadius: 6,
						pointWidth: 9,
						color: toHSL(`${item.organizationName}`),
						dataLabels: {
							enabled: true,
							color: toHSL(`${item.organizationName}`),
						},
					};
				}),
			};
		} catch (error) {
			return null;
		}
	}, [data]);
};
