import React from 'react';
import Styles from './index.module.scss';
import { CircularProgress } from '@mui/material';
interface Props {
	children?: React.ReactNode;
	title: string;
	toolbar?: React.ReactNode;
	isLoading?: boolean;
	minChartHeight?: number;
}

export const Widget = ({
	children,
	title,
	toolbar,
	minChartHeight = 300,
	isLoading = false,
}: Props) => {
	return (
		<div className={Styles.card}>
			<div className={Styles.border}>
				<div className={Styles.header}>
					<h1>{title}</h1>
					<div className={Styles.toolbar}>{toolbar}</div>
				</div>
				{/* {children} */}
				<div className={Styles.content} style={{ minHeight: minChartHeight }}>
					{isLoading && (
						<div className={Styles.loading}>
							<CircularProgress />
						</div>
					)}
					{!isLoading && (
						<div className={Styles.chart} style={{ minHeight: minChartHeight }}>
							{children}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};
