import Styles from './index.module.scss';
import { useAppSelector } from '@/app/hooks/useAppSelector';
import { TotalActionsPerOrganization } from '@/components/Dashboard/TotalActionsPerOrganization';
import { AcceptanceTimeWidget } from '@/components/Dashboard/AcceptanceTimeWidget';
import { ResponseRatio } from '@/components/Dashboard/ResponseRatioWidget';
import { PortalPageLayout } from '@/layouts/PortalPageLayout';
import { useActionDashboardQuery } from '@/app/hooks/useActionDashboardQuery';
import { RefreshIcon } from '@/components/Elements/Icons';

export const Dashboard = () => {
	const filter = useAppSelector((state) => state.filter);
	const { data, isFetching, refetch } = useActionDashboardQuery(filter);
	const { totalActionsSeries, acceptedTimeSeries, responseRationSeries } = data;

	return (
		<PortalPageLayout
			title="Dashboard"
			displayFilter={true}
			displayPeriodSelector={true}
			navigation={
				<div className={Styles.menu}>
					<button
						className={Styles.refreshButton}
						title="Refresh"
						type="button"
						disabled={isFetching}
						onClick={async () => {
							try {
								await refetch?.();
							} catch {}
						}}
					>
						<RefreshIcon />
					</button>
				</div>
			}
		>
			<div className={Styles.container}>
				<TotalActionsPerOrganization isLoading={isFetching} data={totalActionsSeries} />
				<AcceptanceTimeWidget isLoading={isFetching} data={acceptedTimeSeries} />
				<div className={Styles.fullRow}>
					<ResponseRatio isLoading={isFetching} data={responseRationSeries} />
				</div>
			</div>
		</PortalPageLayout>
	);
};
