import { useMemo } from 'react';
import { ColorString } from 'highcharts';
import { ResponseRatioFilter, ResponseRatioItem } from '@/app/types/Dashboard';

export const config: Highcharts.Options = {
	chart: {
		type: 'column',
		style: {
			backgroundColor: 'transparent',
		},
		height: 360,
	},
	accessibility: {
		enabled: false,
	},
	plotOptions: {
		column: {
			pointPadding: 0,
			grouping: true,
			groupPadding: 0.46,
		},
	},
	credits: {
		enabled: false,
	},
	title: {
		text: '',
	},
	tooltip: {
		style: {
			fontFamily: 'Graphik',
			fontSize: '10px',
		},
		headerFormat: '',
		pointFormatter: function () {
			return `${this.category}</br>				          
						<p class="tooltip-header"><span style="color:${this.color as ColorString}">\u25CF</span>
						${this.series.name}</br><b> 
						Total: ${this.y}</p>						
						</b>
						`;
		},
	},
	legend: {
		itemStyle: {
			fontSize: '10px',
			fontFamily: 'Graphik',
		},
		symbolHeight: 7,
		symbolWidth: 7,
		symbolPadding: 3,
		align: 'center',
		verticalAlign: 'bottom',
		layout: 'horizontal',
		enabled: false,
	},
	xAxis: {
		visible: true,
		gridLineWidth: 0,
		crosshair: true,
		labels: {
			enabled: true,
			style: {
				fontSize: '12px',
				fontFamily: 'Graphik',
			},
		},
	},
	yAxis: {
		title: {
			text: 'Total Actions',
			style: {
				fontSize: '14px',
				color: '#B6B6B6',
				fontFamily: 'Graphik',
				fontWeight: '600',
				textOverflow: 'none',
				width: 150,
			},
			x: -16,
		},

		min: 0,
		tickAmount: 4,
		alignTicks: false,
		allowDecimals: false,
		startOnTick: false,
		gridLineDashStyle: 'Dash',
		gridLineColor: '#9194A1',
	},
	series: [],
};

export const useFormattedData = (
	data: ResponseRatioItem[] = [],
	filter: ResponseRatioFilter = 'all'
) => {
	return useMemo(() => {
		try {
			if (data.length === 0) {
				return null;
			}

			const acceptedSeriesItem = {
				name: 'Accepted',
				type: 'column',
				borderRadius: 0,
				pointWidth: 25,
				color: '#1AE5B2',
				data: [
					...data.map((t) => {
						return t.accepted;
					}),
				],
			};

			const rejectedSeriesItem = {
				name: 'Declined',
				type: 'column',
				borderRadius: 0,
				pointWidth: 25,
				color: '#AD022D',
				data: [
					...data.map((t) => {
						return t.rejected;
					}),
				],
			};

			const unansweredSeriesItem = {
				name: 'No Response',
				type: 'column',
				borderRadius: 0,
				pointWidth: 25,
				color: '#EA6101',
				data: [
					...data.map((t) => {
						return t.unanswered;
					}),
				],
			};

			const seriesFilter = {
				all: [acceptedSeriesItem, rejectedSeriesItem, unansweredSeriesItem],
				accepted: [acceptedSeriesItem],
				declined: [rejectedSeriesItem],
				unanswered: [unansweredSeriesItem],
			};

			return {
				...config,
				plotOptions: {
					column: {
						pointPadding: 0,
						grouping: true,
						groupPadding: 0.5 - Math.min(data.length, 9) / 25,
					},
				},
				series: seriesFilter[filter],
				xAxis: {
					...config.xAxis,
					categories: [
						...data.map((t) => {
							return t.organizationName;
						}),
					],
				},
			};
		} catch (error) {
			return null;
		}
	}, [data, filter]);
};
